import React from 'react'
import "../styles/Header.scss"
function Header(props) {
  return (
    <div className='header'>
      <h1>{props.text}</h1>
      <div className="line"></div>
    </div>
  )
}

export default Header
