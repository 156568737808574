import React, { useState } from "react";
import { getDatabase, ref, update} from "firebase/database";
import { app } from "../Firebase";
import Header from "../comps/Header";
import { useLocation, useNavigate } from "react-router-dom";

export default function UpdateContact() {
  const location = useLocation();
  const navigate = useNavigate();


  const [fname, setFname] = useState(location.state[1].Client_Fname);
  const [lname, setLname] = useState(location.state[1].Client_Lname);
  const [phone, setPhone] = useState(location.state[1].Client_Phone);
  const [email, setEmail] = useState(location.state[1].Client_Email);
  const [msg, setMsg] = useState(location.state[1].Client_Message);

  console.log(location.state)

  const onHandleSubmit = (e) => {
    e.preventDefault();
    const db = getDatabase(app);
    const contactsRef = ref(db, `car/${location.state[0]}`);
   
    update(contactsRef,  {
      Client_Fname:fname,
      Client_Lname:lname,
      Client_Phone:phone,
      Client_Email:email,
      Client_Message:msg,
    })
  




   

    navigate("/contacts");
  };

  return (
    <>

      <div className="mainarea">
        <Header text="Update Info" />

        <form action="#" onSubmit={onHandleSubmit}>
          <input
            type="text"
            placeholder="name"
            value={fname}
            onChange={(e) => setFname(e.target.value)}
          />
           <input
            type="text"
            placeholder="name"
            value={lname}
            onChange={(e) => setLname(e.target.value)}
          />
          <input
            type="text"
            placeholder="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="text"
            placeholder="phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          <textarea
            name=""
            id=""
            cols="30"
            rows="10"
            value={msg}
            onChange={(e) => setMsg(e.target.value)}
          ></textarea>
          <button type="submit" className="btn">
            Update Info
          </button>
        </form>
      </div>
    </>
  );
}
