import React, { useState, useEffect } from "react";
import { getDatabase, ref, update, push, onValue } from "firebase/database";
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import { app } from "../Firebase";
import Header from "../comps/Header";
import { notification } from "antd";
import "../styles/UploadBlog.scss";
import { useLocation, useNavigate } from "react-router-dom";

export default function UpdateBlog() {
  const location = useLocation();
  const [title, setTitle] = useState(location.state[1].Title);
  const [category, setCategory] = useState(location.state[1].Category);
  const [language, setLanguage] = useState(location.state[1].Language);
  const [content, setContent] = useState(location.state[1].Content);
  const [imgOldUrl, setImgOldUrl] = useState(location.state[1].Img);
  const [img, setImg] = useState(null);
  const [api, contextHolder] = notification.useNotification();
  const navigate = useNavigate();

  const [blogsData, setBlogsData] = useState([]);

  console.log(location.state[1]);

  const handleUpload = (event) => {
    const file = event.target.files[0];

    // Check if a file is selected
    if (file) {
      // Check file size (limit to 3MB)
      if (file.size > 2 * 1024 * 1024) {
        alert("File size exceeds 2 MB. Please select a smaller file.");
        return;
      }

      // Check file type
      const allowedFileTypes = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "image/webp",
      ];
      if (!allowedFileTypes.includes(file.type)) {
        alert(
          "Invalid file type. Please select a valid image file (JPEG, JPG, PNG, WebP)."
        );
        return;
      }

      // File is valid, set it in the state
      setImg(file);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!img) {
      alert("Please select a valid feature image");
      return;
    }

    const db = getDatabase(app);
    const storage = getStorage(app);

    const blogId = location.state[0]; // Get the existing blog ID from the location state
    const blogRef = ref(db, `Blogs/${blogId}`);

    // Generate a unique key for the image storage location
    const imageStorageKey = blogRef.key;
    const myRef = storageRef(storage, `images/${imageStorageKey}`);

    await uploadBytes(myRef, img);



    console.log(imgUrl);
    console.log(imgOldUrl);

    if (getDownloadURL(myRef)){
        imgUrl = await getDownloadURL(myRef);
        console.log(imgUrl);
    }
    else {
        var imgUrl = imgOldUrl;
        console.log(imgUrl);
    }

    console.log(imgUrl);
;

    if (language === "" || language === "sel" || language === "add") {
      alert("Please select a valid Language");
    } else if (category === "" || category === "sel" || category === "add") {
      alert("Please select a valid Category");
    } else {
      const blogEntry = {
        Id: blogId, // Use the existing blog ID
        Title: title,
        Language: language,
        Category: category,
        Img: imgUrl,
        Content: content,
      };

      update(blogRef, blogEntry); // Update the existing blog entry

      const openNotificationWithIcon = () => {
        api.success({
          message: "Blog Updated", // Update the success message
          description: "",
        });
      };
      openNotificationWithIcon();

      setTitle("");
      setContent("");
      setCategory("");
      setLanguage("");
      setImg(null);

      navigate("/allblogs");
    }
  };

  const [newCat, setNewCat] = useState(true);
  const handleCat = (event) => {
    const enteredCategory = event.target.value;

    setCategory(enteredCategory);

    if (enteredCategory === "add") {
      setNewCat(false);
    }
  };

  const [newLang, setNewLang] = useState(true);

  const handleLang = (event) => {
    setLanguage(event.target.value);

    if (event.target.value === "add") {
      setNewLang(false);
    }
  };

  const closeLang = () => {
    setNewLang(true);
    setLanguage("sel");
  };
  const closeCat = () => {
    setNewCat(true);
    setCategory("sel");
  };

  useEffect(() => {
    const db = getDatabase(app);
    const contactRef = ref(db, "Blogs");
    onValue(contactRef, (snapshot) => {
      const data = snapshot.val();
      setBlogsData(data);
    });
  }, []);

  return (
    <>
      {contextHolder}
      <div className="mainarea">
        <Header text="Update Blog" />

        <form className="upload" action="#" onSubmit={handleSubmit}>
          <input type="file" className="img" onChange={handleUpload} />
          
{/* 
          <div className="imgbox" style={{background: `"url(${location.state[1].Img})"`}}    ></div> */}

          <input
            type="text"
            placeholder="Blog Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />

          <div className="mid">
            {newCat ? (
              <label>
                <span>Category</span>
                <select
                  name=""
                  id=""
                  value={category}
                  onChange={handleCat}
                  required
                >
                  <option value="sel" defaultValue>
                    Select an Option
                  </option>
                  <option value="add">add new</option>
                  {blogsData &&
                    Array.from(
                      new Set(
                        Object.values(blogsData).map((value) => value.Category)
                      )
                    ).map((category) => (
                      <option key={category} value={category}>
                        {category}
                      </option>
                    ))}
                </select>
              </label>
            ) : (
              <div className="side-btn">
                <input
                  type="text"
                  placeholder="New Category"
                  onChange={(e) => setCategory(e.target.value)}
                />

                <span className="btn" onClick={() => closeCat()}>
                  X
                </span>
              </div>
            )}
            {newLang ? (
              <label>
                <span>Language</span>
                <select value={language} onChange={handleLang} required>
                  <option value="sel" defaultValue>
                    Select an Option
                  </option>
                  <option value="add">add new</option>
                  {blogsData &&
                    Array.from(
                      new Set(
                        Object.values(blogsData).map((value) => value.Language)
                      )
                    ).map((language) => (
                      <option key={language} value={language}>
                        {language}
                      </option>
                    ))}
                </select>
              </label>
            ) : (
              <div className="side-btn">
                <input
                  type="text"
                  placeholder="New Language"
                  onChange={(e) => setLanguage(e.target.value)}
                />

                <span className="btn" onClick={() => closeLang()}>
                  {" "}
                  X
                </span>
              </div>
            )}
          </div>

          <textarea
            placeholder="Content goes here"
            cols="30"
            rows="10"
            value={content}
            onChange={(e) => setContent(e.target.value)}
            required
          ></textarea>

          <button
            type="submit"
            style={{ width: "fit-content" }}
            className="btn"
          >
            Upload Blog
          </button>
        </form>
      </div>
    </>
  );
}
