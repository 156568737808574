import React, { useEffect, useState } from "react";
import Header from "../comps/Header";
import { MdModeEdit } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { getDatabase, onValue, ref, remove } from "firebase/database";
import { app } from "../Firebase";
import { MdOutlineArrowUpward } from "react-icons/md";

function ContactsList() {
  const [contactData, setContactData] = useState(null);
  const [visibleContacts, setVisibleContacts] = useState(5);
  const [totalEntries, setTotalEntries] = useState(0); // State variable to store total number of entries
  const Navigate = useNavigate();

  useEffect(() => {
    const db = getDatabase(app);
    const contactRef = ref(db, "car");
    
    // Retrieve data and calculate total number of entries
    onValue(contactRef, (snapshot) => {
      const data = snapshot.val();
      setContactData(data);
      if (data) {
        const entriesCount = Object.keys(data).length;
        setTotalEntries(entriesCount);
      }
    });
  }, []);

  console.log(contactData);

  const deleteData = (key) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this Contact?");
    if (confirmDelete) {
      const db = getDatabase(app);
      const contactRef = ref(db, "car/" + key);
      remove(contactRef);
    }
  };
  
  const loadMore = () => {
    setVisibleContacts((prevVisibleContacts) => prevVisibleContacts + 10);
  };

  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="mainarea">
      <Header text="All Contacts" id="top" />
      <a href="#top" className={showButton ? "top" : "top inv"}>
        <MdOutlineArrowUpward />
      </a>
      {contactData ? (
        <div className="tablebox">
          <table>
            <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Phone no.</th>
                <th>Message</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(contactData)
                .slice(0, visibleContacts)
                .map(([key, value]) => (
                  <tr key={key}>
                    <td>{value.Client_Fname}</td>
                    <td>{value.Client_Lname}</td>
                    <td>{value.Client_Phone}</td>
                    <td>{value.Client_Email}</td>
                    <td>{value.Client_Message}</td>

                    <td className="actions">
                      <button onClick={() => Navigate('/update', { state: [key, value] })}>
                        <MdModeEdit />
                      </button>
                      <button onClick={() => deleteData(key)}>
                        <MdDelete />
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {Object.entries(contactData).length > visibleContacts && (
            <div className="btn-box">
              <button className="btn" onClick={loadMore}>
                Load More
              </button>
            </div>
          )}
        </div>
      ) : (
        <p>No data found till now</p>
      )}
      
    </div>
  );
}

export default ContactsList;
