import React, { useState, useEffect } from "react";
import Header from "../comps/Header";
import CountUp from "react-countup";
import "../styles/Dashbaord.scss";
import { getDatabase, onValue, ref } from "firebase/database";
import { app } from "../Firebase";

function Dashbaord() {
  const [totalEntries, setTotalEntries] = useState(0);
  const [totalEntriesB, setTotalEntriesB] = useState(0);

  useEffect(() => {
    const db = getDatabase(app);
    const contactRef = ref(db, "car");
    const blogRef = ref(db, "Blogs");

    // Retrieve data and calculate total number of entries
    onValue(contactRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const entriesCount = Object.keys(data).length;
        setTotalEntries(entriesCount);
      }
    });
    onValue(blogRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const entriesCount = Object.keys(data).length;
        setTotalEntriesB(entriesCount);
      }
    });
  }, []);

  return (
    <div className="mainarea">
      <Header text="Dashbaord" />

      <div className="cards-box">
        <div className="card">
          <div className="box">
            <h1>
              <CountUp end={totalEntries} />
            </h1>
          </div>
          <span className="text">Contacts</span>
        </div>

        {/* <div className="card">
          <div className="box">
            <h1>
              <CountUp end={totalEntriesB} />
            </h1>
          </div>
          <span className="text">Blogs</span>
        </div> */}
      </div>
    </div>
  );
}

export default Dashbaord;
