import React from 'react'
import "../styles/BlogTemp.scss"




function BlogTemp() {
  return (
    <div className='blogtemp'>
      <div className="content-width">
       <div className="topbox">
        <div className="imgbox"></div>
        <div className="detailsbox"></div>
       </div>
      </div>
    </div>
  )
}

export default BlogTemp
