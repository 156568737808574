import React, { useState } from "react";
import { FaRegEye } from "react-icons/fa";
import { AiFillEyeInvisible } from "react-icons/ai";
import "../styles/Login.scss";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { app } from "../Firebase";
import { useNavigate } from "react-router";
import { notification } from 'antd';

function Login({ grantAuth }) {
  const [passToggle, setPassToggle] = useState(true);
  const [userName, setUserName] = useState("");
  const [userPass, setUserPass] = useState("");
  const moveto = useNavigate();
  const [api, contextHolder] = notification.useNotification();


  const handleLogin = (event) => {
    event.preventDefault();

    const auth = getAuth(app);
    signInWithEmailAndPassword(auth, userName, userPass)
      .then((userCredential) => {
        const user = userCredential.user;

        if (typeof grantAuth === 'function') {
          grantAuth();
        }
        moveto("/");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        const openNotificationWithIcon = () => {
            api.error({
              message: "Ooops....!",
              description: errorMessage,
            });
          };
        
        openNotificationWithIcon();
      });
  };

  return (
    <>
          {contextHolder}
      <div className="parent login">
        <div className="login-left">
          <h2>Login</h2>
          <form onSubmit={handleLogin}>
            <div className="input">
              <input
                type="text"
                value={userName}
                placeholder="UserName"
                onChange={(e) => setUserName(e.target.value)}
              />
            </div>
            <div className="input">
              <input
                type={passToggle ? "password" : "text"}
                value={userPass}
                placeholder="Password"
                onChange={(e) => setUserPass(e.target.value)}
              />
              <button type="button" onClick={() => setPassToggle(!passToggle)}>
                {passToggle ? <AiFillEyeInvisible /> : <FaRegEye />}
              </button>
            </div>
            <button type="submit" className="btn">
              Login
            </button>
          </form>
        </div>
        <div className="login-right bg-img-cover">
          <h1>Welcome to Saidigitek</h1>
        </div>
      </div>
    </>
  );
}

export default Login;
