import React, { useState } from "react";
import { getDatabase, ref, set, push } from "firebase/database";
import { app } from "../Firebase";
import Header from "../comps/Header";
import { notification } from 'antd';

function ContactForm() {
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [mssg, setmssg] = useState("");
  const [api, contextHolder] = notification.useNotification();

  const handleSubmit = (event) => {
    event.preventDefault();

    const db = getDatabase(app);
    const contactsRef = ref(db, "Contacts");
    const newContactRef = push(contactsRef); // Generate a unique key

    // Set the data with the generated unique key
    set(newContactRef, {
      Id: newContactRef.key, // Use the unique key generated by push as the ID
      Name: name,
      Phone: phone,
      Email: email,
      Message: mssg
    });

    // Show success notification
    const openNotificationWithIcon = () => {
      api.success({
        message: "Message sent successfully!",
        description: "Thank you for contacting us",
      });
    };
    openNotificationWithIcon();

    // Clear input fields after submission
    setname("");
    setemail("");
    setmssg("");
    setphone("");
  };

  return (
    <>
      {contextHolder}
      <div className="mainarea">
        <Header text="Add Contacts" />

        <form action="#" onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="name"
            value={name}
            onChange={(e) => setname(e.target.value)}   
          />
          <input
            type="text"
            placeholder="email"
            value={email}
            onChange={(e) => setemail(e.target.value)}
          />
          <input
            type="text"
            placeholder="phone"
            value={phone}
            onChange={(e) => setphone(e.target.value)}
          />
          <textarea
            name=""
            id=""
            cols="30"
            rows="10"
            value={mssg}
            onChange={(e) => setmssg(e.target.value)}
          ></textarea>
          <button type="submit" className="btn">
            Submit
          </button>
        </form>
      </div>
    </>
  );
}

export default ContactForm;
